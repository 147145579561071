import { Yes } from '@dfds-ui/icons/system'
import { ListIcon } from '@dfds-ui/react-components'
import styled from '@emotion/styled'

import { CardTextBlock, FlexCard } from '../Card'
import { gridMap } from '../../utils'

const StyledTable = styled.table`
  width: 100%;

  tr:not(:first-child) {
    border-top: 1px solid #cccccc;
  }
  td {
    padding: 12px 0;
  }
  td:not(:first-child) {
    padding: 12px 6px;
    text-align: center;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: default;
    color: #49a2df;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #baddf3;
    color: #4d4e4c;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    top: -4px;
    left: 25px;
    z-index: 1;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

const InfoBlockLoyaltyWhy = () => {
  return (
    <FlexCard width={gridMap['one-third']}>
      <CardTextBlock title={'Why join?'} text={'All member get access to:'} />
      <StyledTable>
        <tr>
          <td>
            Special promotions for members only
            <br />
            (always on and seasonal)
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>Birthday offer</td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Ticket flexibility{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Loyalty members get a ticket flexibility of +/- 4 hours -
                always.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>Early access to special events</td>
          <td width="20%">
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Kids eat for free in our restaurants onboard{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Loyalty members get offered free meals for kids between 0 - 5
                years old.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
        <tr>
          <td>
            Complimentary water in the restaurants{' '}
            <span className="tooltip">
              &#9432;
              <span className="tooltiptext">
                Loyalty members get a complimentary water in the restaurant.
              </span>
            </span>
          </td>
          <td>
            <ListIcon icon={Yes} color={'#999999'} />
          </td>
        </tr>
      </StyledTable>
    </FlexCard>
  )
}

export default InfoBlockLoyaltyWhy
